.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

:root {
    --headerHeight: 64px;
    --footerHeight: 35px;
    --breadcrumbHeight: 28px;
}

#mainLayout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #F8F8F8;
    align-items: center;

}

#mainBoxLeft{
    margin: 12px;
}

.main {
    height: calc(100vh - var(--headerHeight) - var(--footerHeight) - var(--breadcrumbHeight));
    overflow-y: scroll;
    /*-ms-overflow-style: none;  !* IE and Edge *!*/
    /*scrollbar-width: none;  !* Firefox *!*/
}

.main::-webkit-scrollbar {
    /*display: none;*/
}

.closeButton {
    position: absolute;
    top: 16px;
    right: 24px;
}
.closeButton :hover{
  cursor: pointer;
}
.customAccordion{
    transition: height 1s ease-in;
    height: 40px;
    &.expanded {
        height: auto;
    }
}
.hoverItem :hover{
    cursor: pointer;
}

.whole_iclinic{
    margin: 12px 12px 12px 0;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.main_iclinic {
    margin: 12px 12px 12px 0;
    width: calc(100vw - 475px);
    max-height: 100%;
    overflow: hidden;
    border-radius: 8px;
}
.answerText{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}
.extensionUrl{
    position: absolute;
    left: 45px;
    top: 21px;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #656565;
    text-decoration: underline;
}
.extensionUrl:hover{
    cursor: pointer;
}
.findHelpUrl{
    position: absolute;
    right: 21px;
    top: 17px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #656565;
    text-decoration: underline;
}
.findHelpUrl:hover{
    cursor: pointer;
}

.relative{
    position: relative;
}

.hidden { display:none; }

.collapseBox{
    padding: 16px 15.5px 0 69px;
}

.notCollapseBox{
    padding: 16px 15.5px 0 15.5px
}

.sidebarCollapseIcon{
    position: absolute;
    top: 30px;
    right: 16px;
    font-size: 24px;
    width: 24px;
    height: 24px;
    z-index: 100;
}
.sidebarCollapseIcon:hover{
    cursor: pointer;
}
.sidebarCollapseIcon svg:hover{
    cursor: pointer;
}

.mainContentCollapseIcon{
    position: absolute;
    top: 30px;
    left: 16px;
    font-size: 24px;
    width: 24px;
    height: 24px;
    z-index: 100;
}
.mainContentCollapseIcon:hover{
    cursor: pointer;
}
.mainContentCollapseIcon svg:hover{
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    #mainBoxLeft{
        margin: 12px;
    }
    .main_iclinic {
        margin: 12px 12px 12px 0;
        width: calc(100vw - 436px);
    }
}

@media print {
    .collapseBox{
        padding: 16px 15.5px 0 15.5px;
    }
    .mainContentCollapseIcon{
        display: none;
    }
    .whole_iclinic{
        margin: 0;
        margin-left: 0px !important;
        width: 100vw;
        overflow: visible;
    }
    .main_iclinic {
        margin: 0;
        margin-left: 0px !important;
        width: 100vw;
        overflow: visible;
    }
    .customAccordion{
        height: auto;
    }
}

.stepper {
    width: 100%;
    overflow-x: scroll;
    /*-ms-overflow-style: none;  !* IE and Edge *!*/
    /*scrollbar-width: none;  !* Firefox *!*/
}

.stepper::-webkit-scrollbar {
    /*display: none;*/
}

.appLogo {
    height: 64px;
    width: 150px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
}

.beyondHealthAppLogoContainer {
    padding: 18px 0 18px 50px;
}

.beyondHealthAppLogo {
    height: 36px;
    width: 193px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
}

.beyondHealthFooter {
    position: absolute;
    bottom: 24px;
    left: 111px;
}

.beyondHealthLogo {
    width: 33.77px;
    height: 33.82px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
}

.beyondHealthLogoText {
    font-size: 16px;
    font-weight: 700;
    line-height: 21.86px;
    letter-spacing: 1.5px;
    text-align: left;
    padding-left: 11px;
}

.appLogo:hover {
    cursor: pointer;
}

.emptyImageContainer {
    width: 232px;
    height: 232px;
    border-radius: 50%;
    background-color: #EEF3F9;
    position: relative;
}

.emptyImage {
    width: 162px;
    height: 184px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
    position: absolute;
    top: 29px;
    right: 52px;
    bottom: 19px;
    left: 18px;
}

.mdLandLogo {
    position: absolute;
    bottom: 34px;
    width: 94px;
    height: 21px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
}

.flex {
    display: flex;
}

.table-wrapper {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    overflow: auto;
    white-space: normal;
}

.greyBackground {
    background: #F8F8F8;
    color: rgba(67, 67, 67, 100);
    width: fit-content;
}

.red {
    color: #EB5757
}

.icdSpan {
    padding: 4px;
    border-radius: 5px;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    background: #EFEFEF
}

@media print{
    @page {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}