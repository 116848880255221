body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media print {
  body {
    visibility: hidden;
  }
  .section-to-print {
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
      overflow: visible;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
  }
  .no-scroll-print{
      overflow: visible!important;
  }
    .section-to-print::-webkit-scrollbar {
        display: none;
    }
  .no-print{
    display: none !important;
    visibility: hidden;
      position: absolute;
      z-index: -1;
  }
}